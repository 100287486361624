<template>
    <v-card class="elevation-25 my-3">
        <v-row>
            <v-col cols="12" class="pt-0">
                <v-card-title class="pt-0">
                    <h3 class="card-title pmd-title-separator pt-5">
                        {{$t('$vuetify.sessionsPage.tabs.remainingSessions')}}
                    </h3>
                    <v-spacer></v-spacer>
                    <v-btn rounded color="primary mt-0" small @click="$router.push('order/products')">
                      {{$t('$vuetify.RemainingSessions.action')}}
                    </v-btn>
                </v-card-title>
                <!--                    <v-card-title>
                                        بسته های من
                                        <v-spacer></v-spacer>
                                        <v-btn class="elevation-0" color="primary" small @click="$router.push('order/products')">
                                            خرید بسته جدید
                                            <v-icon class="ma-1" small>fas fa-plus</v-icon>
                                        </v-btn>
                                    </v-card-title>-->
                <v-divider></v-divider>
            </v-col>
        </v-row>

        <v-tabs
                v-model="tab"
        >
            <v-tab
                    v-for="(cat,i) in getUserInfo.allWallets" :key="i"
            >
                {{ $vuetify.rtl ? cat.title : cat.en_title }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                    v-for="(cat,i) in getUserInfo.allWallets" :key="i"
            >
                <v-row dense class="mb-4 px-5 pb-10"
                       style="border-bottom:1px solid rgba(0,0,0,.3)">
                    <template v-if="cat.data.global_packages !=0 || cat.data.dr_packages!=0 ">


                        <v-col cols="12" sm="6" md="6" lg="4" class="text-center mt-3"
                               v-if="cat.data.global_packages != 0">
                            <v-card
                                    class="text-center background-gray"
                            >
                                <v-card-text>
                                    <v-avatar
                                            :size="100"
                                            class="elevation-25"
                                            v-if="platform!=null"
                                    >
                                        <v-img
                                                class="elevation-25"
                                                :src="platform.logo"
                                        ></v-img>
                                    </v-avatar>
                                </v-card-text>

                                <v-card-text class="text-center title pa-0 ma-0">
                                  <template v-if="$vuetify.rtl">
                                    <span>{{cat.title}}</span>
                                  </template>
                                  <template v-else>
                                    <span>{{cat.en_title}}</span>
                                  </template>
                                </v-card-text>

                                <v-card-subtitle>
                                  <template v-if="$vuetify.rtl">
                                    <span>{{cat.title}}</span>
                                  </template>
                                  <template v-else>
                                    <span>{{cat.en_title}}</span>
                                  </template>
                                    {{$t('$vuetify.RemainingSessions.count[1]')}}
                                    :
                                    <v-chip class="font-weight-bold white--text chip-number " style="direction:ltr;"
                                            :class="{'red':cat.data.global_packages<0,'primary':cat.data.global_packages>0}">
                                        {{cat.data.global_packages}}
                                    </v-chip>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                        <v-col v-for="(pack,index) in cat.data.drPackages" :key="index"
                               cols="12" sm="6" md="6" lg="4"
                               class="text-center mt-3">
                            <v-card
                                    class="text-center "
                            >
                                <v-card-text>
                                    <v-avatar
                                            :size="100"
                                            class="elevation-25"
                                    >
                                        <v-img class="elevation-25"
                                               :src="pack.avatar"
                                        ></v-img>
                                    </v-avatar>
                                </v-card-text>

                                <v-card-text class="text-center title pa-0 ma-0">
                                  <template v-if="$vuetify.rtl">
                                    <span>{{pack.full_name}}</span>
                                  </template>
                                  <template v-else>
                                    <span>{{pack.en_name}}</span>
                                  </template>
                                </v-card-text>

                                <v-card-subtitle v-if="pack.hasOwnProperty('total')">
                                    <template v-if="$vuetify.rtl">
                                      {{ cat.title }}
                                    </template>
                                  <template v-else>
                                    {{cat.en_title}}
                                  </template>
                                  {{$t('$vuetify.RemainingSessions.doctor')}}
                                  <template v-if="$vuetify.rtl">
                                    <span>{{pack.full_name}}</span>
                                  </template>
                                  <template v-else>
                                    <span>{{pack.en_name}}</span>
                                  </template>
                                    <v-chip :class="{'orange' : pack.total <= 0,'primary':pack.total >0}"
                                            class="font-weight-bold white--text chip-number">{{pack.total}}
                                    </v-chip>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>

                    </template>
                    <template v-else>
                        <v-col
                                cols="12"
                        >
                            <v-card :min-height="300" class="pt-12 elevation-0">
                                <v-card-text class="text-center pa-5 my-auto title font-weight-bold">
                                    <div>
                                      {{$t('$vuetify.RemainingSessions.noSession.title')}}
                                      <div>
                                            <v-btn color="primary" class="title pa-2 px-5 ma-5"
                                                   @click="$router.push('order/products')">
                                              {{$t('$vuetify.RemainingSessions.noSession.action')}}
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
        <!--<v-row v-for="(cat,i) in getUserInfo.allWallets" :key="i" dense class="mb-4 px-5 pb-10"-->
        <!--style="border-bottom:1px solid rgba(0,0,0,.3)">-->
        <!--<v-col cols="12">-->
        <!--<span>{{cat.title}}</span>-->
        <!--</v-col>-->

        <!--<v-col cols="12" sm="6" md="6" lg="4" class="text-center" v-if="cat.data.global_packages > 0">-->
        <!--<v-card-->
        <!--class="text-center background-gray"-->
        <!--&gt;-->
        <!--<v-card-text>-->
        <!--<v-avatar-->
        <!--:size="100"-->
        <!--class="elevation-25"-->
        <!--v-if="platform!=null"-->
        <!--&gt;-->
        <!--<v-img-->
        <!--class="elevation-25"-->
        <!--:src="platform.logo"-->
        <!--&gt;</v-img>-->
        <!--</v-avatar>-->
        <!--</v-card-text>-->

        <!--<v-card-text class="text-center title pa-0 ma-0">-->
        <!--<span>جلسات مشاوره روانشناسی</span>-->
        <!--</v-card-text>-->

        <!--<v-card-subtitle>-->
        <!--تعداد جلسات استفاده نشده :-->
        <!--<v-chip class="font-weight-bold primary white&#45;&#45;text chip-number">-->
        <!--{{cat.data.global_packages}}-->
        <!--</v-chip>-->
        <!--</v-card-subtitle>-->
        <!--</v-card>-->
        <!--</v-col>-->
        <!--<v-col v-for="(pack,index) in cat.data.drPackages" :key="index"-->
        <!--cols="12" sm="6" md="6" lg="4"-->
        <!--class="text-center">-->
        <!--<v-card-->
        <!--class="text-center "-->
        <!--&gt;-->
        <!--<v-card-text>-->
        <!--<v-avatar-->
        <!--:size="100"-->
        <!--class="elevation-25"-->
        <!--&gt;-->
        <!--<v-img class="elevation-25"-->
        <!--:src="pack.avatar"-->
        <!--&gt;</v-img>-->
        <!--</v-avatar>-->
        <!--</v-card-text>-->

        <!--<v-card-text class="text-center title pa-0 ma-0">-->
        <!--<span>{{pack.full_name}}</span>-->
        <!--</v-card-text>-->

        <!--<v-card-subtitle v-if="pack.hasOwnProperty('total')">-->
        <!--{{cat.title}} با دکتر {{pack.full_name}}:-->
        <!--<v-chip :class="{'orange' : pack.total <= 0,'primary':pack.total >0}"-->
        <!--class="font-weight-bold white&#45;&#45;text chip-number">{{pack.total}}-->
        <!--</v-chip>-->
        <!--</v-card-subtitle>-->
        <!--</v-card>-->
        <!--</v-col>-->
        <!--</v-row>-->
        <!--<v-row-->
        <!--v-if="!hasPackages"-->
        <!--&gt;-->
        <!--<v-col-->
        <!--cols="12"-->
        <!--&gt;-->
        <!--<v-card :min-height="300" class="pt-12 elevation-0">-->
        <!--<v-card-text class="text-center pa-5 my-auto title font-weight-bold">-->
        <!--<span>-->
        <!--شما هیچ بسته ای ندارید-->
        <!--<div>-->
        <!--<v-btn color="primary" class="font-weight-bold title pa-2 ma-5"-->
        <!--@click="$router.push('order/products')">خرید کنید</v-btn>-->
        <!--</div>-->
        <!--</span>-->
        <!--</v-card-text>-->
        <!--</v-card>-->
        <!--</v-col>-->
        <!--</v-row>-->
    </v-card>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                tab: 0
            }
        },
        computed: {
            hasPackages() {
                return this.availableDoctorPackages.length || this.hasGlobalPackages;
            },
            availableDoctorPackages() {
                return this.getUserInfo.wallet.drPackages || [];
            },
            hasGlobalPackages() {
                return this.getUserInfo.wallet.global_packages > 0 ? this.getUserInfo.wallet.global_packages : false;
            },
            ...mapGetters({
                getUserInfo: 'getUserInfo',
                platform: 'getPlatform'
            })
        },
        methods:{
          enWalletTitle(input){
            switch (input){
              case "جلسات باقی مانده":
                return this.$t('$vuetify.ClientNavItem.remainingSessions')
              case "تست های باقی مانده":
                return this.$t('$vuetify.factorsPage.walletMessage.RemainingTests')
              case "سوالات باقی مانده":
                return this.$t('$vuetify.factorsPage.walletMessage.RemainingQuestions')
            }
          }
        }
    }
</script>

<style scoped>

</style>