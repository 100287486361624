<template>
    <v-container class="py-0 px-3">
        <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
        <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>

        <v-card class="elevation-0">

            <v-tabs
                    v-model="tab"
            >
                <v-tabs-slider color="primary"></v-tabs-slider>

                  <v-tab>{{$t('$vuetify.sessionsPage.tabs.confirmSessions')}}</v-tab>
                <v-tab>{{$t('$vuetify.sessionsPage.tabs.pendingSessions')}}</v-tab>
                <v-tab>{{$t('$vuetify.sessionsPage.tabs.remainingSessions')}}</v-tab>
            </v-tabs>


            <v-tabs-items v-model="tab">
                <v-tab-item
                >
                    <div class="background-gray" v-if="sessions.length">
                        <v-row justify="right">
                            <v-col cols="12" sm="6" md="4" lg="4" v-for="(session,index) in sessions" :key="index">
                                <SessionCard :session="session"></SessionCard>
                            </v-col>
                        </v-row>

                        <div class="text-center">
                            <v-pagination
                                    v-model="page"
                                    :length="total"
                            ></v-pagination>
                        </div>
                    </div>
                    <v-card-text class="text-center" v-else>
                        {{$t('$vuetify.sessionsPage.noSession')}}
                    </v-card-text>
                </v-tab-item>

                <v-tab-item>
                    <ClientAwaitingSessions></ClientAwaitingSessions>
                </v-tab-item>
                <v-tab-item>
                    <RemainingSessions></RemainingSessions>
                </v-tab-item>
            </v-tabs-items>


        </v-card>
    </v-container>
</template>


<script>
    // import ClientSessions from '@/components/clientComponents/ClientSessionsComponent';
    import SessionCard from '@/components/sessions/SessionCard';
    import {EventBus} from "@/event-bus";
    import RemainingSessions from '@/components/clients/RemainingSessions';
    import ClientAwaitingSessions from '@/components/sessions/clientAwaitingSessions.vue'
    export default {
        created() {
            this.getClientSessions();
        },
        data() {
            return {
                tab: 0,
                page: 1,
                total: 1,
                update: false,
                sessions: [],
                items: [
                    {
                        text: this.$t('$vuetify.dashboard'),
                        disabled: false,
                        to: `/${this.$route.params.lang}/panel/dashboard`,
                    },
                    {
                        text: this.$t('$vuetify.ClientNavItem.sessions'),
                        disabled: true,
                        to: `/${this.$route.params.lang}/panel/sessions`,
                    }
                ]
            }
        },
        methods: {
            fetAllSessions() {
                this.getClientSessions();
            },
            updateSessions() {
                this.update = true;
            },
            getClientSessions() {
                // let options = {
                //     state: 'date_fixed|used',
                //     orderBy: "session_date:desc"
                // };
                //
                // if (this.activeCycle != null) {
                //     options.cycle_id = this.activeCycle.id;
                // }
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getClientAllSessions', {page: this.page}).then((data) => {
                    this.total = data.data.pagination.total_pages;
                    this.sessions = [];
                    this.sessions = data.data.sessions;
                    // this.total = data.paginate.totalPages
                    this.$emit('updated');
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                });
            },
        },
        components: {
            SessionCard,
            RemainingSessions,
            // ClientSessions,
            ClientAwaitingSessions
        },
        watch: {
            update() {
                this.getClientSessions();
            },
            page() {
                this.getClientSessions();
                this.$vuetify.goTo(this.$refs.sessions.offsetTop);
            }
        }

    }
</script>

<style scoped>

</style>